import logo from './logo.svg';
import './App.css';
import { Loot } from './Loot';

function App() {
  return (
    <>
      <Loot/>
    </>
  );
}

export default App;
