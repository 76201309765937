import React, { Component } from 'react';
import tiers from './tiers.json';
import classes from './classes.json';

const rarity_colors = {
    'rare': '#4471f7',
    'uncommon': '#1eff00',
    'common' : '#fff',
    'epic' : '#a335ee',
    'legendary': '#ff8000'
}

class Item extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            info: props
        }
    }


    name()
    {
        return (<div style={{'color': this.state.info.props.rarity ? rarity_colors[this.state.info.props.rarity.toLowerCase()] : rarity_colors['common']}}>{this.state.info.props.name}</div>)
    }

    onClick(url)
    {
        url ? window.open(url, '_blank') : console.log('no url found for ' + this.state.info.props.name);
    }

    bop() {
        if (this.state.info.props.bop === 'bop')
        {
            return <div> Binds when picked up</div>
        } else if (this.state.info.props.bop === 'boe')
        {
            return <div> Binds when equiped </div>
        } else {
            return <></>
        }
    }

    unique() {
        return this.state.info.props.unique ? <div> {this.state.info.props.unique} </div> : <></>
    }

    itemSlot(){
        return (
            <div className='item-slot-type'>
                <div>{this.state.info.props.slot}</div>
                <div>{this.state.info.props.type}</div>
            </div>)
    }

    ilvl()
    {
        // console.log(this.state.info.props.ilvl);
        return (this.state.info.props.ilvl ? <div className='ilvl'> Item Level: {this.state.info.props.ilvl} </div> : <></>)
    }

    stats()
    {

        return ( this.state.info.props.stats ? 
                <div className={this.state.info.props.weapon ? 'weapon-stats-container' : 'armor-stats-container' }>            
                    {this.state.info.props.stats.map((s)=> {
                        if (s === "<Random enchantment>"){
                            return (<div style={{color: '#1eff0b'}} key={s}>{s}</div>)
                        } else {
                            return (<div key={s}>{s}</div>)
                        }
                    })}
                </div>
            : <></>)
    }

    classes()
    {
        return (
            this.state.info.props.class ? 
                <div style={{display: 'flex', flexWrap: 'wrap'}}>Classes: 
                    {this.state.info.props.class.map((c) => {
                        return (<span key={c} style={{color: classes[c].color, paddingLeft: "5px"}}>{c}</span>)
                    })}
                </div>
            : <></>
        )
    }

    reqLvl()
    {
        return (this.state.info.props.req_lvl ? <div>Requires Level {this.state.info.props.req_lvl}</div> : <></>)

    }

    effects()
    {
        return (this.state.info.props.effects ? 
                <div className='effects-container'>
                    {this.state.info.props.effects.map((e)=>{
                        return <div key={e}>{e}</div>
                    })}
                </div> 
            : <></>
        )
        
    }

    flavorText()
    {
        return (this.state.info.props.flavor ? <div className='flavor-text'>{this.state.info.props.flavor}</div> : <></>)
    }

    tier()
    {
        return (
            this.state.info.props.tierset ? 
                <div className='tier-container'>
                    <div className='tier-title'> {Object.values(tiers[this.state.info.props.tierset].title)} </div>
                    <div className='tier-items-container'> {
                        Object.values(tiers[this.state.info.props.tierset].items).map((i)=> {
                            return (<p key={i}>{i}</p>);
                        })} 
                    </div>
                    <div className='tier-bonus-container'> {
                        Object.values(tiers[this.state.info.props.tierset].set_bonus).map((i)=> {
                            return (<p key={i}>{i}</p>);
                        })} 
                    </div>
                </div> 
            : <></>
        )
    }

    dropChance()
    {
        return (this.state.info.props.drop_chance ? 
            <div>Drop chance: {this.state.info.props.drop_chance} </div>
            : <></>)
    }

    recipeHeader()
    {
        return (this.state.info.props.recipe_name ? 
        <div>
            <div style={{color: rarity_colors[this.state.info.props.recipe_rarity]}}>{this.state.info.props.recipe_name}</div>
            <div>
                {this.state.info.props.recipe_requirements.map((e)=>{
                    return <div key={e}>{e}</div>
                })}
            </div>
            <div className={this.state.info.props.name ? 'recipe-use recipe-space' : 'recipe-use'}>{this.state.info.props.recipe_use}</div>
        </div>
        : <></>)
    }

    recipeFooter()
    {
        return (this.state.info.props.recipe_footer ? 
            <div className='recipe-footer'>{this.state.info.props.recipe_footer}</div>
            : <></>)
    }


    // item class renders tooltips in the order defined below
    // see individual functions for details
    // item info is found in the items.json file
    // see the item_json_template file for json specifics
    render() {
        return (
            <div>
                 {this.state.info.props.name || this.state.info.props.recipe_name ? <div className='item' onClick={()=>{this.onClick(this.state.info.props.url)}}>
                    {this.recipeHeader()}
                    {this.name()}
                    {this.ilvl()}
                    {this.bop()}
                    {this.unique()}
                    {this.itemSlot()}
                    {this.stats()}
                    {this.classes()}
                    {this.reqLvl()}
                    {this.effects()}
                    {this.flavorText()}
                    {this.tier()}
                    {this.dropChance()}
                    {this.recipeFooter()}
                </div> : <></>}
            </div>
        )
    }

    // render() {
    //     return ( this.state.info.props.name ?
    //         <div>
    //             <div className='item' onClick={()=>{this.onClick(this.state.info.props.url)}}>
    //                 {this.name()}
    //                 {this.ilvl()}
    //                 {this.bop()}
    //                 {this.unique()}
    //                 {this.itemSlot()}
    //                 {this.stats()}
    //                 {this.classes()}
    //                 {this.reqLvl()}
    //                 {this.effects()}
    //                 {this.flavorText()}
    //                 {this.tier()}
    //                 {this.dropChance()}
    //             </div>
    //         </div>
    //     : <></>)
    // }

}

export default Item;